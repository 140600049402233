(function() {
  'use strict';

  angular
    .module('app')
    .config(config)
    .run(run);

  config.$inject = [
    '$breadcrumbProvider',
    '$compileProvider',
    '$httpProvider',
    '$stateProvider',
    'stConfig',
    '$translateProvider',
    'uiGmapGoogleMapApiProvider',
    '$urlRouterProvider'
  ];

  run.$inject = [
    'helpers',
    '$rootScope',
    '$state',
    '$stateParams'
  ];

  function config(
    $breadcrumbProvider,
    $compileProvider,
    $httpProvider,
    $stateProvider,
    stConfig,
    $translateProvider,
    uiGmapGoogleMapApiProvider,
    $urlRouterProvider
  ) {
    // Disable debug info
    $compileProvider.debugInfoEnabled(false);

    // App abstract state
    $stateProvider
      .state('app', {
        abstract: true,
        views: {
          'main': {
            templateUrl: 'templates/main.html',
            controller: 'MainController as main'
          }
        }
      });
    $urlRouterProvider.otherwise('/');

    $translateProvider.useUrlLoader('https://api.lec.csw.backofficeprojectdata.com/v1.0/incms/translate?_format=json&lang=en')

      .preferredLanguage(window.localStorage[ 'language' ] || 'es')
      .fallbackLanguage(window.localStorage[ 'language' ] || 'es')
      .useSanitizeValueStrategy('sanitizeParameters');

    $breadcrumbProvider.setOptions({
      prefixStateName: 'app.dashboard',
      templateUrl: 'components/breadcrumb/breadcrumb.html'
    });

    $httpProvider.interceptors.push('authInterceptor');

    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyBqycrWFXV64IuZPNHpTMobyXxUjz7oEeE',
      libraries: 'weather,geometry,visualization',
    });

    stConfig.pagination.template = 'components/tablePaginator/tablePaginator.html';
    stConfig.sort.skipNatural = true;
  }

  function run(
    helpers,
    $rootScope,
    $state,
    $stateParams
  ) {
    angular.element(document).ready(function() {
      helpers.redirectByPermissions($state.current.name);
    });

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    $rootScope.$on('$stateChangeStart', function( event, toState, toParams, fromState, fromParams ) {
      helpers.redirectByPermissions( toState.name );
    });
  }
})();
