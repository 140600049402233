(function() {
  'use strict';

  angular
    .module('app')
    .service('attributeService', attributeService);

  attributeService.$inject = [];

  function attributeService() {

    return {
      getTypeOptions: getTypeOptions,
    };

    function getTypeOptions() {
      return [
        { id: 'A30', name: 'A30' },
        { id: 'A10', name: 'A10' },
        { id: 'A20', name: 'A20' },
        { id: 'AG', name: 'AG' },
        { id: 'INT', name: 'INT' },
      ]
    }
  }
})();
